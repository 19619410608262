import {getDatabase, onValue, ref} from "@firebase/database"
import {initializeApp} from 'firebase/app';
import * as API from "@/api";

import {getAuth, User} from "firebase/auth"
//import firebase from "firebase/app";
import firebase from 'firebase/compat/app';
import * as firebaseui from "firebaseui";
import "firebase/auth"
import {setUserEmail} from "@/api";

//import firebase2 from "firebase/compat"

//import {getAuth, User} from "firebase/auth"

// Это не секреты
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAcfEJXGvm9sUK8sAwvD3IDzP3mJ4jy8dk",
    authDomain: "ticketcafe.firebaseapp.com",
    databaseURL: "https://ticketcafe-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "ticketcafe",
    storageBucket: "ticketcafe.appspot.com",
    messagingSenderId: "635114167262",
    appId: "1:635114167262:web:371ef0af354bfec7771b4f",
    measurementId: "G-2K474VD08Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//export const analytics = getAnalytics(app)

const auth = getAuth(app)

const firebaseUI = new firebaseui.auth.AuthUI(auth);

const uiConfig = {
    callbacks: {
        signInSuccessWithAuthResult: function (authResult, redirectUrl) {
            // User successfully signed in.
            // Return type determines whether we continue the redirect automatically
            // or whether we leave that to developer to handle.
            return true;
        },
        uiShown: function () {
            // The widget is rendered.
            // Hide the loader.
            //document.getElementById('loader').style.display = 'none';
        }
    },
    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    signInFlow: 'popup',
    signInSuccessUrl: '/', // /banner  /orders
    signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        // firebase.auth.GithubAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        // firebase.auth.PhoneAuthProvider.PROVIDER_ID
    ],
    // Terms of service url.
    //tosUrl: '<your-tos-url>',
    // Privacy policy url.
    //privacyPolicyUrl: '<your-privacy-policy-url>'
}


// Initialize Performance Monitoring and get a reference to the service
//const perf = getPerformance(app)

const rtdb = getDatabase(app, 'https://ticketcafe-default-rtdb.asia-southeast1.firebasedatabase.app/')

function getRef(path?: string) {
    return ref(rtdb, API.FIRE_URL + '/' + path)
}

export function getCurrentUser(): Promise<User | null> {
    return new Promise((resolve, reject) => {
        const unsubscribe = getAuth().onAuthStateChanged(user => {
            unsubscribe()
            resolve(user)
        }, reject)
    })
}

getAuth().onAuthStateChanged(user => {
    if (user?.email)
        setUserEmail(user?.email)
})

export const RTDB = {
    firebaseUI,
    //auth,
    uiConfig,
    app,
    getRef,
    onValue
} as const


// export function getOrderRef(id) {
//     return ref(rtdb, `/orders_new2/${id}`)
// }

// export function getPhotoTypes(onlyOnce = true): Promise<PhotoTypes> {
//     return new Promise((resolve, reject) => {
//         onValue(ref(rtdb, 'photoTypes'), snapshot =>
//                 snapshot.val() ? resolve(snapshot.val()) : reject('orderTypes !snapshot.exists()')
//             , {onlyOnce})
//     })
// }


